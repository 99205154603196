import axios from "axios";

const baseUrl = process.env.REACT_APP_DATA_SERVICE_URL;

const FlowValues = () => {

    const getFlowValues = async(serialNr) => {

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        let interval = 120
        if (serialNr == "lochristi_declercq") {
            interval = 60 * 3
        } if (serialNr == "DEDECKER_VANDEMOORTELE") {
            interval = 300
        }

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: baseUrl + '/api/status/' + serialNr,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            params: {
                interval: interval
            }
        }

        try {
            const response = await axios.get(config.url, config);
            return response.data
        } catch (error) {
            throw error;
        }

    }

    return {
        getFlowValues,
    }

}

export default FlowValues