import React, { useState, useEffect, useCallback } from "react"
import { formatCurrency, formatNumber } from "../../../utils/helpers"
import HeaderOfferte from "./header"
import SysteemRijen from "./systeemRijen"
import OfferteExtra from "./offerteExtra"

const Offerte = ({
    klant,
    reps,
    install,
    producten,
    thisOrder,
    bebaten,
    btws,
    paymentOptions,
    totaleOmvormcapaciteit,
    setTotaleOmvormcapaciteit,
    totaleBatterijcapaciteit,
    setTotaleBatterijcapaciteit,
    prices,
    setPrices,
    setSystemsVerhoudingen,
    setSystemsCapaciteiten
}) => {
    const [totalOrder, setTotalOrder] = useState(thisOrder)
    const [systemenTotaal, setSystemenTotaal] = useState(0)
    const [systemenGewichtTotaal, setSystemenGewichtTotaal] = useState(0)
    const [controllerList, setControllerList] = useState([])
    const [energiemeterList, setEnergiemeterList] = useState([])
    const [abonnementList, setAbonnementList] = useState([])
    const [btwList, setBtwList] = useState([])
    const [bebatList, setBebatList] = useState([])
    const [btw, setBtw] = useState(0)
    const [totaalExclBtw, setTotaalExclBtw] = useState(0)
    const [totaalInclBtw, setTotaalInclBtw] = useState(0)
    const [totalOmvormers, setTotalOmvormers] = useState(0)

    const controllers = producten.toebehoren.controllers
    const energiemeters = producten.toebehoren.energiemeters
    const abonnementen = producten.toebehoren.abonnementen

    const buitenbehuizingPrijs = producten.buitenbehuizing.verkoopprijs
    const buitenbehuizingAantal = totalOrder.buitenbehuizing?.num || 0
    const buitenbehuizingTotaal = buitenbehuizingAantal * buitenbehuizingPrijs

    useEffect(() => {
        const createOptions = (options) => options.map(option => ({
            value: option.label,
            text: option.label
        }));

        setControllerList(createOptions(controllers?.options || []));
        setEnergiemeterList(createOptions(energiemeters?.options || []));
        setAbonnementList(createOptions(abonnementen?.options || []));
        setBebatList(createOptions(bebaten?.options || []));
        setBtwList(createOptions(btws?.options || []));
    }, [producten, controllers, energiemeters, abonnementen, btws]);

    const handleSelect = (e) => {
        const { name, value } = e.target
        setTotalOrder(prevOrder => ({
            ...prevOrder,
            [name]: {
                ...prevOrder[name],
                key: value,
                num: prevOrder[name]?.num || 1
            }
        }))
    }

    const handleCounter = (e) => {
        const { name, value } = e.target
        setTotalOrder((prevOrder) => ({
            ...prevOrder,
            [name]: {
                ...prevOrder[name],
                num: parseInt(value, 10) || 0
            }
        }))
    }

    const calculatePrices = useCallback(() => {
        const getPrice = (list, key) => {
            const selectedItem = list.find(item => item.label === totalOrder[key]?.key)
            return selectedItem?.verkoopprijs || 0
        }

        const controllerPrijs = getPrice(controllers.options, 'controller')
        const energiemeterPrijs = getPrice(energiemeters.options, 'energiemeter')
        let abonnementPrijs = getPrice(abonnementen.options, 'abonnement');
        if (totalOrder.abonnement?.key === "Optimalisatie software abonnement sturing") {
            const grootAboObj = abonnementen.options.find(option => option.label === "Data abonnement groot");
            const grootAboPrijs = grootAboObj?.verkoopprijs || 0;
            const optimalisatieAboObj = abonnementen.options.find(option => option.label === "Optimalisatie software abonnement sturing");
            const optimalisatieAboPrijs = optimalisatieAboObj?.verkoopprijs || 0;
            abonnementPrijs = optimalisatieAboPrijs * totaleBatterijcapaciteit / 5.12 * 5 * totalOmvormers + grootAboPrijs;
        }

        const totaalControllerPrijs = controllerPrijs * (totalOrder.controller?.num || 0)
        const totaalEnergiemeterPrijs = energiemeterPrijs * (totalOrder.energiemeter?.num || 0)
        const totaalAbonnementPrijs = abonnementPrijs * (totalOrder.abonnement?.num || 0)

        const systemenAndBehuizingPrice = systemenTotaal + buitenbehuizingTotaal
        const korting = (klant.korting / 100) * systemenAndBehuizingPrice

        const selectedBebat = bebaten.options.find(b => b.label === totalOrder.bebat?.key)
        const bebatPrijsPerKg = selectedBebat?.value || 0
        const bebatPrijs = systemenGewichtTotaal * bebatPrijsPerKg

        const totaalExclBtw = systemenAndBehuizingPrice + totaalControllerPrijs + totaalEnergiemeterPrijs
            + totaalAbonnementPrijs + totalOrder.kleineMaterialen.total - korting + bebatPrijs

        const selectedBtw = btws.options.find(btw => btw.label === totalOrder.btw?.key)
        const btwPercentage = selectedBtw?.value || 0
        const btwPrijs = totaalExclBtw * (btwPercentage / 100)
        const totaalInclBtw = totaalExclBtw + btwPrijs

        const totaalPrijsPremies = systemenTotaal + buitenbehuizingTotaal + totaalControllerPrijs + totaalEnergiemeterPrijs - korting;

        setPrices({
            controllerPrijs,
            totaalControllerPrijs,
            energiemeterPrijs,
            totaalEnergiemeterPrijs,
            abonnementPrijs,
            totaalAbonnementPrijs,
            korting,
            bebatPrijsPerKg,
            bebatPrijs,
            btwPrijs,
            totaalExclBtw,
            totaalInclBtw,
            totaalPrijsPremies
        })

        setBtw(btwPercentage)
        setTotaalExclBtw(totaalExclBtw)
        setTotaalInclBtw(totaalInclBtw)
    }, [controllers.options, energiemeters.options, totalOrder, abonnementen.options, buitenbehuizingTotaal, klant.korting, producten.data.tariefBebat.value, systemenGewichtTotaal, systemenTotaal, btws.options, setPrices, totalOmvormers, totaleBatterijcapaciteit]);

    useEffect(() => {
        calculatePrices()
    }, [totalOrder, calculatePrices])

    const SelectRow = ({ name, list, label }) => (
        <tr className="offerte__table__row">
            <td className="offerte__table__cell">
                <select
                    onChange={handleSelect}
                    value={totalOrder[name]?.key || ""}
                    name={name}
                    className="offerte__select"
                    style={{ height: (totalOrder[name]?.key?.length || 0) > 50 ? "4rem" : "auto" }}
                >
                    <option value="" disabled>Selecteer {label}</option>
                    {list.map(item => (
                        <option key={item.value} value={item.value}>
                            {item.text}
                        </option>
                    ))}
                </select>
            </td>
            <td className="offerte__table__cell">
                <input
                    type="number"
                    name={name}
                    min={0}
                    onChange={handleCounter}
                    value={totalOrder[name]?.num || 0}
                    className="offerte__input"
                />
            </td>
            <td className="offerte__table__cell">{formatCurrency(prices[`${name}Prijs`])}</td>
            <td className="offerte__table__cell">{formatCurrency(prices[`totaal${label}Prijs`])}</td>
        </tr>
    );

    return (<>
        <div className='offerte__page'>
            <HeaderOfferte install={install} klant={klant} reps={reps} />
            {klant.aansluitvermogen < totaleOmvormcapaciteit &&
                <div className="offerte__warning">
                    <h4 className='offerte__header offerte__header--red'>HUIDIGE SELECTIE NIET OK</h4>
                    <p className="offerte__p--red">Selectie is hoger dan aansluitvermogen</p>
                </div>
            }
            <table className='offerte__table offerte__brown-table'>
                <thead className="offerte__table__thead">
                    <tr className="offerte__table__row">
                        <th className='offerte__table__header offerte__table__header--eight-wide'>Omschrijving</th>
                        <th className="offerte__table__header offerte__table__header--two-wide">Aantal</th>
                        <th className='offerte__table__header offerte__table__header--three-wide'>Eenheidsprijs</th>
                        <th className='offerte__table__header offerte__table__header--three-wide'>Totaal</th>
                    </tr>
                </thead>
                <tbody>
                    <SysteemRijen
                        producten={producten}
                        totalOrder={totalOrder}
                        setTotalOrder={setTotalOrder}
                        handleCounter={handleCounter}
                        handleSelect={handleSelect}
                        updateSystemTotal={setSystemenTotaal}
                        updateTotalWeight={setSystemenGewichtTotaal}
                        updateTotalBatterijCap={setTotaleBatterijcapaciteit}
                        updateTotalOmvormCap={setTotaleOmvormcapaciteit}
                        updateTotalOmvormers={setTotalOmvormers}
                        updateSystemsVerhoudingen={setSystemsVerhoudingen}
                        updateSystemsCapaciteiten={setSystemsCapaciteiten}
                    />

                    <tr className="offerte__table__row">
                        <td className='offerte__table__cell'><h4 className='offerte__header'>Buitenbehuizing:</h4></td>
                        <td className='offerte__table__cell'>
                            <input
                                type='number'
                                name='buitenbehuizing'
                                onChange={handleCounter}
                                min={0}
                                value={buitenbehuizingAantal}
                                className="offerte__input"
                            />
                        </td>
                        <td className='offerte__table__cell'>{formatCurrency(buitenbehuizingPrijs)}</td>
                        <td className='offerte__table__cell'>{formatCurrency(buitenbehuizingTotaal)}</td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className='offerte__table__cell' colSpan={4}>
                            <h4 className='offerte__header'>Toebehoren:</h4>
                        </td>
                    </tr>
                    <SelectRow name="controller" list={controllerList} label="Controller" />
                    <SelectRow name="energiemeter" list={energiemeterList} label="Energiemeter" />
                    <SelectRow name="abonnement" list={abonnementList} label="Abonnement" />
                    {totalOrder.kleineMaterialen.list.length > 0 && (
                        <>
                            <tr className="offerte__table__row">
                                <td className="offerte__table__cell" colSpan={4}>
                                    <h4 className='offerte__header'>Kleine Materialen/Diversen:</h4>
                                </td>
                            </tr>
                            {totalOrder.kleineMaterialen.list.map((item, index) => (
                                <tr className="offerte__table__row" key={index}>
                                    <td className="offerte__table__cell">{item.klOmschrijving}</td>
                                    <td className="offerte__table__cell">{item.klAantal} <i>stuk(s)</i></td>
                                    <td className="offerte__table__cell">{formatCurrency(item.klEenheid)}</td>
                                    <td className="offerte__table__cell">{formatCurrency(item.klAantal * item.klEenheid)}</td>
                                </tr>
                            ))}
                        </>
                    )}
                    {klant.korting > 0 && (
                        <tr className="offerte__table__row">
                            <td className="offerte__table__cell"><h4 className='offerte__header'>Commerciële korting (enkel op de systemen en de buitenbehuizing):</h4></td>
                            <td className="offerte__table__cell"><i>{klant.korting}% excl BTW</i></td>
                            <td className="offerte__table__cell"></td>
                            <td>{formatCurrency(-(prices.korting))}</td>
                        </tr>
                    )}
                    {systemenGewichtTotaal > 0 && (
                        <tr className="offerte__table__row">
                            <td className="offerte__table__cell">
                                <div className="offerte__bebat-container">
                                    <h4 className='offerte__header'>Bebat:</h4>
                                    <select
                                        id="offerte__bebat-select"
                                        name="bebat"
                                        onChange={handleSelect}
                                        value={totalOrder.bebat?.key || ""}
                                        className="offerte__select"
                                    >
                                        {bebatList.map((bebatOption) => (
                                            <option key={bebatOption.value} value={bebatOption.value}>
                                                {bebatOption.text}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td className="offerte__table__cell">{formatNumber(systemenGewichtTotaal)} kg</td>
                            <td className="offerte__table__cell">{formatCurrency(prices.bebatPrijsPerKg)}/kg</td>
                            <td className="offerte__table__cell">{formatCurrency(prices.bebatPrijs)}</td>
                        </tr>
                    )}
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell" colSpan={3}><b>Totaal excl. BTW:</b></td>
                        <td className="offerte__table__cell"><b>{formatCurrency(totaalExclBtw)}</b></td>
                    </tr>
                    <tr className="offerte__table__row">
                        <td className="offerte__table__cell">
                            <select
                                id="offerte__btw-select"
                                name="btw"
                                onChange={handleSelect}
                                value={totalOrder.btw?.key || ""}
                                className="offerte__select"
                            >
                                {btwList.map((btwOption) => (
                                    <option key={btwOption.value} value={btwOption.value}>
                                        {btwOption.text}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td className="offerte__table__cell">{btw}%</td>
                        <td className="offerte__table__cell"></td>
                        <td className="offerte__table__cell">{formatCurrency(prices.btwPrijs)}</td>
                    </tr>
                </tbody>
                <tfoot className="offerte__table__footer">
                    <tr className="offerte__table__row">
                        <th className="offerte__table__header"><b>Totaal incl. BTW:</b></th>
                        <th className="offerte__table__header"></th>
                        <th className="offerte__table__header"></th>
                        <th className="offerte__table__header"><b>{formatCurrency(totaalInclBtw)}</b></th>
                    </tr>
                </tfoot>
            </table>
        </div>
        <OfferteExtra
            totalOrder={totalOrder}
            updateTotalOrder={setTotalOrder}
            klant={klant}
            paymentOptions={paymentOptions}
            reps={reps}
        />
    </>);
}

export default Offerte