import AdminDashboard from "../AdminDashboard/adminDashboard";
import ContactPage from "../HomePage/ContactPage/contactPage";
import React, { useEffect } from 'react';
import ControllerPage from '../HomePage/ControllerPage/controllerPage';
import CurtailmentPage from "../HomePage/Curtailment-Page/curtailmentPage";
import Dashboard from '../Dashboard/dashboard';
import HomePage from '../HomePage/homePage';
import HomePageFooter from '../HomePage/HomePage-Components/homePageFooter';
import HomePageHeader from '../HomePage/HomePageNavbar/homePageHeader';
import HomePageNavBar from '../HomePage/HomePageNavbar/homePageNavbar';
import NewsPage from '../HomePage/NewsPage/newsPage';
import NewsDetailPage from "../HomePage/NewsPage/newsDetailPage";
import OplossingenPage from '../HomePage/OplossingenPage/oplossingenPage';
import BatteryTower from '../HomePage/OplossingenPage/oplossingen/batteryTower';
import PartnersPage from "../HomePage/PartnersPage/partnersPage";
import Powerblock from '../HomePage/OplossingenPage/oplossingen/powerblock';
import Container from '../HomePage/OplossingenPage/oplossingen/container';
import Sector from '../HomePage/SectorenPage/sector';
import SignUpPage from '../HomePage/SignUpForm/signUpForm';
import HomePageBodyPage from "../HomePage/HomePageBodyPages/homePageBodyPage";
import RequestSimulationForm from "../HomePage/RequestSimulation/requestSimulationForm";

const ScrollToTop = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return children;
};

const scrollToNews = () => {
    const newsSection = document.getElementById('homePage__news');
    if (newsSection) {
        newsSection.scrollIntoView({ behavior: 'smooth' });
    }
};

const scrollToSimulatie = () => {
    const simulationSection = document.getElementById('homePage__simulation');
    if (simulationSection) {
        simulationSection.scrollIntoView({ behavior:'smooth' });
    }
}

export const ContactRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <ContactPage />
        <HomePageFooter />
    </ScrollToTop>
);

export const ControllerRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <ControllerPage />
        <HomePageFooter />
    </ScrollToTop>
);

export const CurtailmentRoute = () => (
    <ScrollToTop>
        <CurtailmentPage />
    </ScrollToTop>
);

export const DashboardRoute = () => (
    <ScrollToTop>
        <Dashboard />
    </ScrollToTop>
);

export const FormRoute = () => (
    <ScrollToTop>
        <SignUpPage />
    </ScrollToTop>
);

export const WebAdminPage = () => (
    <div>
        <AdminDashboard />
    </div>
);

export const HomeRoute = () => (
    <ScrollToTop>
        <HomePageHeader scrollToNews={scrollToNews} scrollToSimulatie={scrollToSimulatie} />
        <HomePage />
        <HomePageFooter />
    </ScrollToTop>
);

export const HomePageBodyRoute = ({ kader }) => (
    <ScrollToTop>
        <HomePageNavBar />
        <HomePageBodyPage kader={kader} />
        <HomePageFooter />
    </ScrollToTop>
);

export const NewsRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <NewsPage />
        <HomePageFooter />
    </ScrollToTop>
);

export const NewsDetailRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <NewsDetailPage />
        <HomePageFooter />
    </ScrollToTop>
);

export const OplossingenRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <OplossingenPage />
        <HomePageFooter />
    </ScrollToTop>
);

export const BatteryTowerRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <BatteryTower />
        <HomePageFooter />
    </ScrollToTop>
);

export const PartnersRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <PartnersPage />
        <HomePageFooter />
    </ScrollToTop>
);

export const PowerblockRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <Powerblock />
        <HomePageFooter />
    </ScrollToTop>
);

export const ContainerRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <Container />
        <HomePageFooter />
    </ScrollToTop>
);

export const SectorRoute = ({ sector }) => (
    <ScrollToTop>
        <HomePageNavBar />
        <Sector sector={sector} />
        <HomePageFooter />
    </ScrollToTop>
);

export const RequestSimulationRoute = () => (
    <ScrollToTop>
        <HomePageNavBar />
        <RequestSimulationForm />
        <HomePageFooter />
    </ScrollToTop>
);
