import React from 'react';
import { useParams } from 'react-router-dom';
import { useNewsById, formatDate } from '../Utils/useNews';
import './newsDetailPage.css';

const NewsDetailPage = () => {
    const { id } = useParams();
    const { article, loading, error } = useNewsById(id);

    if (loading) {
        return <p>Artikel wordt geladen...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    if (!article) {
        return <p>Artikel niet gevonden.</p>;
    }

    const images = [
        article.mainImage,
        ...(article.additionalImages || []),
    ].filter((image) => typeof image === 'string' && image.trim() !== '');

    return (
        <div className="newsDetailPage__body">
            <h1 className="newsDetailPage__title">{article.shortDescription}</h1>
            <p><strong>Datum:</strong> {formatDate(article.createdAt)}</p>
            <div className="newsDetailPage__content">
                <p
                    className="newsDetailPage__text"
                    dangerouslySetInnerHTML={{ __html: article.longDescription }}
                />

                <div className="newsDetailPage__images">
                    {images.length === 1 ? (
                        <img
                            src={images[0]}
                            alt="Main Image"
                            className="newsDetailPage__mainImage"
                        />
                    ) : (
                        <div className="newsDetailPage__additionalImages">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Image ${index + 1}`}
                                    className="newsDetailPage__additionalImage"
                                />
                            ))}
                        </div>
                    )}
                </div>

                <p className="newsDetailPage__hashtags">{article.hashtags}</p>
            </div>
        </div>
    );
};

export default NewsDetailPage;
