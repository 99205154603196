import React from 'react';
import { useNews } from '../Utils/useNews';
import './homePageNewsAlert.css';

const HomePageNewsAlert = ({scrollToNews}) => {
    const { newsItems, loading, error } = useNews();

    if (loading || error || newsItems.length === 0) {
        return null;
    }

    const latestNewsItem = newsItems[0];

    return (
        <div className="homePage__news-alert">
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-link">
                <p onClick={scrollToNews} className='homePage__news-alert__link'>Lees Meer Nieuws Hier</p>
            </div>
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-image">
                <img src={latestNewsItem.mainImage} alt="Latest News" />
            </div>
            <div className="homePage__news-alert__hexagon homePage__news-alert__hexagon-text">
                <p>{latestNewsItem.shortDescription}</p>
            </div>
        </div>
    );
};

export default HomePageNewsAlert;
