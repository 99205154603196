import { useEffect, useState } from 'react';
import './savingsMeter.css'
import { CircularProgressbar } from 'react-circular-progressbar';

const SavingsMeter = ({price, darkMode}) => {

    const [rotationValue, setRotationValue] = useState(180 + 45);

    const [scale, setScale] = useState(20);
    const [distance, setDistance] = useState(50);

    useEffect(() => {

        setRotationValue(((price / scale) * 270) - 45)
        setDistance((price / scale) * 75)

    }, [price])

    const valueAfterStyle = {
        borderRadius: '100%',
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        background: `conic-gradient(from -135deg, #04B8FE, #75FF90 ${distance}%, transparent 0%)`,
        WebkitMask: 'radial-gradient(farthest-side, transparent calc(100% - 15px), #fff calc(100% - 14px))',
        mask: 'radial-gradient(farthest-side, transparent calc(100% - 15px), #fff calc(100% - 14px))'
    };

    const glowAfterStyle = {
        borderRadius: '100%',
        display: 'block',
        position: 'absolute',
        top: '10%',
        left: '10%',
        height: '80%',
        width: '80%',
        opacity: '10%',
        background: `conic-gradient(from -135deg, #04B8FE, #75FF90 ${distance}%, transparent 0%)`,
        WebkitMask: 'radial-gradient(farthest-side, transparent calc(100% - 15px), #fff calc(100% - 14px))',
        mask: 'radial-gradient(farthest-side, transparent calc(100% - 15px), #fff calc(100% - 14px))'
    };

    return (
        <div className='dashboard__savings__overdrive__meter'>
            <div className='dashboard__savings__overdrive__meter__background__container'>
                <div style={darkMode ? {border: '15px solid #313233'} : {border: '15px solid #CCCCCC'}} className='dashboard__savings__overdrive__meter__background'></div>
                <div className='dashboard__savings__overdrive__meter__background--value'>
                    <div style={valueAfterStyle}></div>
                </div>
                <div className='dashboard__savings__overdrive__meter__background--value'>
                    <div style={glowAfterStyle}></div>
                </div>
                <div className='dashboard__savings__overdrive__pointer' style={{transform: `translateY(-50%) rotate(${rotationValue}deg)`}}></div>
                <div className={`dashboard__savings__overdrive__meter__value--${price > 0 ? 'reached' : 'unreached'} dashboard__savings__overdrive__meter__value--first`}>0</div>
                <div className={`dashboard__savings__overdrive__meter__value--${price >= scale/4 ? 'reached' : 'unreached'} dashboard__savings__overdrive__meter__value--second`}>{scale/4}</div>
                <div className={`dashboard__savings__overdrive__meter__value--${price >= (scale/4)*2 ? 'reached' : 'unreached'} dashboard__savings__overdrive__meter__value--third`}>{(scale/4) * 2}</div>
                <div className={`dashboard__savings__overdrive__meter__value--${price >= (scale/4)*3 ? 'reached' : 'unreached'} dashboard__savings__overdrive__meter__value--fourth`}>{(scale/4) * 3}</div>
                <div className={`dashboard__savings__overdrive__meter__value--${price >= scale ? 'reached' : 'unreached'} dashboard__savings__overdrive__meter__value--fifth`}>{scale}</div>
            </div>
        </div>
    )

}

export default SavingsMeter