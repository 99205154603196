import React, { useState, useEffect } from 'react';
import './simulationRequestsList.css';
import { formatNumber } from '../offerte/utils/helpers';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SimulationRequestsList = () => {
    const [simulationRequests, setSimulationRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedRequestId, setExpandedRequestId] = useState(null);
    const [stats, setStats] = useState({ simulationPageVisits: 0, simulationRequests: 0, effectiveSimulationRequests: 0 });
    const [logEntries, setLogEntries] = useState([]);
    const [filteredLogEntries, setFilteredLogEntries] = useState([]);
    const [isLogVisible, setIsLogVisible] = useState(false);

    useEffect(() => {
        const fetchSimulationRequests = async () => {
            try {
                const response = await fetch(`${apiUrl}/simulation-request`);
                if (response.ok) {
                    const data = await response.json();
                    setSimulationRequests(data);
                } else {
                    setError('Fout bij het ophalen van de simulatieaanvragen.');
                }
            } catch (error) {
                setError('Er is een fout opgetreden bij het ophalen van de gegevens.');
            } finally {
                setLoading(false);
            }
        };

        const fetchStats = async () => {
            try {
                const response = await fetch(`${apiUrl}/tracking/stats`);
                if (response.ok) {
                    const data = await response.json();
                    setStats(data);
                } else {
                    console.error('Fout bij het ophalen van de statistieken.');
                }
            } catch (error) {
                console.error('Er is een fout opgetreden bij het ophalen van de statistieken:', error);
            }
        };

        const fetchLogEntries = async () => {
            try {
                const response = await fetch(`${apiUrl}/tracking`);
                if (response.ok) {
                    const data = await response.json();
                    setLogEntries(data);
                    setFilteredLogEntries(data);
                } else {
                    console.error('Fout bij het ophalen van de logboekvermeldingen.');
                }
            } catch (error) {
                console.error('Er is een fout opgetreden bij het ophalen van de logboekvermeldingen:', error);
            }
        };

        fetchSimulationRequests();
        fetchStats();
        fetchLogEntries();
    }, []);

    const handleToggleDetails = (requestId) => {
        setExpandedRequestId(expandedRequestId === requestId ? null : requestId);
    };

    const filterLogEntries = (actionType) => {
        const filteredEntries = logEntries.filter(entry => entry.action === actionType);
        setFilteredLogEntries(filteredEntries);
    };

    const resetFilters = () => {
        setFilteredLogEntries(logEntries);
    };

    const toggleLogVisibility = () => {
        setIsLogVisible(!isLogVisible);
    };

    return (
        <div className="simulationrequestslist__container">
            <div className="statistics">
                <div onClick={() => filterLogEntries('click_to_simulation_page')}>
                    <h4>Pagina bezocht:</h4>
                    <p>{stats.simulationPageVisits}</p>
                </div>
                <div onClick={() => filterLogEntries('submit_simulation_request')}>
                    <h4>Aantal simulatie aanvragen:</h4>
                    <p>{stats.simulationRequests}</p>
                    <p className='subtitle'>Dit kunnen onjuist ingevulde en niet verzonden formulieren zijn</p>
                </div>
                <div>
                    <h4>Verstuurde simulatie aanvragen:</h4>
                    <p>{stats.effectiveSimulationRequests}</p>
                </div>
            </div>

            <button onClick={toggleLogVisibility}>
                {isLogVisible ? 'Verberg Logboek' : 'Toon Logboek'}
            </button>

            {isLogVisible && (
                <div className="log-section">
                    <div className='log-header'>
                        <h4>Logboek:</h4>
                        {filteredLogEntries.length !== logEntries.length && (
                            <button onClick={resetFilters}>Toon Alle Logboek Vermeldingen</button>
                        )}
                    </div>
                    <ul>
                        {filteredLogEntries.map((entry, index) => (
                            <li key={index}>
                                {new Date(entry.createdAt).toLocaleString()} - {entry.action}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <h2>Simulatie aanvraag formulieren:</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <table className="simulation-requests-table">
                    <thead>
                        <tr>
                            <th>Onderneming</th>
                            <th>Email</th>
                            <th>Jaarverbruik</th>
                            <th>Productie PV</th>
                            <th>Injectie</th>
                            <th>Aansluitvermogen</th>
                            <th>Aankoop totaalkost</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {simulationRequests.map((request) => (
                            <React.Fragment key={request.id}>
                                <tr>
                                    <td>{request.companyName}</td>
                                    <td>{request.email}</td>
                                    <td>{formatNumber(request.annualUsage)} kWh</td>
                                    <td>{formatNumber(request.pvProduction)} kWh</td>
                                    <td>{formatNumber(request.injection)} kWh</td>
                                    <td>{formatNumber(request.connectionPower)} kVA</td>
                                    <td>{formatNumber(request.purchaseCostPerKwh)} €/kWh</td>
                                    <td>
                                        <button onClick={() => handleToggleDetails(request.id)}>
                                            Details
                                        </button>
                                    </td>
                                </tr>
                                {expandedRequestId === request.id && (
                                    <tr className="details-row">
                                        <td colSpan="11">
                                            <div className="request-details">
                                                <p><strong>Contact Persoon:</strong> {request.contactPerson}</p>
                                                <p><strong>Adres:</strong> {request.address}</p>
                                                <p><strong>Telefoon:</strong> {request.phone}</p>
                                                <p><strong>Datum aanvraag:</strong> {new Date(request.createdAt).toLocaleString()}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SimulationRequestsList;
