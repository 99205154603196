import React, { useState } from 'react';
import './homePageFooter.css';
import logoBrown from '../../../Images/firn-ver.png'
import pdf1 from '../../../pdf/FIRN energy - Algemene voorwaarden.pdf'
import pdf2 from '../../../pdf/FIRN energy - Algemene voorwaarden inzake aankoop goederen en hardware.pdf'
import { trackClickToSimulationPage } from '../Utils/simulationHelper';

const HomePageFooter = () => {
    return (
        <footer className='homePage__footer'>
            <div className="homePage__footer__container">
                <div className="homePage__footer__section homePage__footer__logo-section">
                    <img src={logoBrown} alt="FIRN energy Logo" />
                </div>
                <div className='homePage__footer__column'>
                    <div className="homePage__footer__section homePage__footer__contact-section">
                        <h3>Contacteer ons</h3>
                        <p>Heb je vragen over ons product of platform? We helpen je graag!</p>
                        <address>
                            Parkstraat 23<br />
                            8930 Menen<br />
                            +32 56 19 88 77<br />
                            <a href="mailto:sales@firnenergy.com">sales@firnenergy.com</a><br />
                        </address>
                        <p>BTW BE 0898.104.885</p>
                    </div>
                    <div className="homePage__footer__section homePage__footer__follow-us-section">
                        <h3>Volg ons</h3>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/firnenergy/">LinkedIn</a></li>
                            <li><a href="https://www.facebook.com/profile.php?id=61551960005278">Facebook</a></li>
                            <li><a href="https://www.youtube.com/@FIRNenergy">YouTube</a></li>
                        </ul>
                    </div>
                    <div className="homePage__footer__section homePage__footer__follow-partners-section">
                        <h3>Onze Partners</h3>
                        <ul>
                            <li><a href="/Partners">Partners</a></li>
                        </ul>
                    </div>
                </div>
                <div className='homePage__footer__section homePage__footer__column'>
                    <div className="homePage__footer__section homePage__footer__solutions-section">
                        <h3>Oplossingen</h3>
                        <ul>
                            <li><a href="/BatteryTower">Batterij toren</a></li>
                            <li><a href="/Powerblock">FIRN Power Block</a></li>
                            <li><a href="/Container">Container</a></li>
                        </ul>
                    </div>
                    <div className="homePage__footer__section homePage__footer__ai-section">
                        <h3>AI Based</h3>
                        <p><a href="/Controller">FIRN energy Controller</a></p>
                    </div>
                    <div className="homePage__footer__section homePage__footer__sectoren-section">
                        <h3>Sectoren</h3>
                        <ul>
                            <li><a href="/Industrie">Industrie</a></li>
                            <li><a href="/Residentieel">Residentieel</a></li>
                            <li><a href="/Landbouw">Landbouw</a></li>
                            <li><a href="/Horeca">Horeca</a></li>
                        </ul>
                    </div>
                    <div className="homePage__footer__section homePage__footer__follow-news-section">
                        <h3>Laatste nieuws</h3>
                        <ul>
                            <li><a href="/Nieuws">Nieuws</a></li>
                        </ul>
                    </div>
                </div>
                <div className="homePage__footer__section homePage__footer__request-simulation-section">
                    <h3>Vraag simulatie aan</h3>
                    <p>Ontdek hoe onze oplossingen jouw energiebeheer kunnen optimaliseren. Vraag een simulatie aan en ervaar het zelf!</p>
                    <a href="/SimulatieAanvragen">
                        <button type="button" id="homePage__simulation" onClick={trackClickToSimulationPage}>Simulatie aanvragen</button>
                    </a>
                </div>
            </div>
            <div className="homePage__footer-bottom">
                <a href={pdf1}>Algemene voorwaarden</a><span> | </span>
                <a href={pdf2}>Aankoop goederen & hardware</a>
            </div>
        </footer>
    );
}

export default HomePageFooter;
