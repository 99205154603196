export const trackClickToSimulationPage = async () => {
    console.log('Tracking click to simulation page...');
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tracking`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ action: 'click_to_simulation_page' }),
        });

        if (response.ok) {
            console.log('Click to simulation page tracked.');
            window.location.href = '/SimulatieAanvragen';
        } else {
            console.error('Failed to track click.');
        }
    } catch (error) {
        console.error('Error tracking click:', error);
    }
};
