import React, {createContext, useState, useEffect } from 'react';

const UserContext = createContext();

function UserProvider(props) {
    const [userMock, setUserMock] = useState(() => {
        // Load userMock from localstorage when app initializes
        const storedUser = localStorage.getItem("userMock");
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        // Save userMock to localstorage whenever it changes
        if (userMock) {
            localStorage.setItem("userMock", JSON.stringify(userMock));
        } else {
            localStorage.removeItem("userMock");
        }
    }, [userMock])

    const login = (userData) => {
        setUserMock(userData);
    };

    const mockLogout = () => {
        setUserMock(null);
    };

    return (
        <UserContext.Provider value={{ userMock, login, mockLogout }}>
            {props.children}
        </UserContext.Provider>
    )
}

export {UserContext, UserProvider}